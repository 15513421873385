import React, { useCallback, useEffect, useState } from 'react'
import DataTable from '../../../../components/DataTable'
import { nameEntity, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import Container from '../../../../components/Container'
import { StringParam, useQueryParam } from 'use-query-params'
import Form, { Input, Select } from '../../../../components/Form'
import { useHistory } from 'react-router-dom'
import Modal from '../../../../components/Modal'
import { useLoading } from '../../../../hooks/loading'
import api from '../../../../services/api'
import { FormChangeStatus } from '../components/FormChangeStatus'
import { useUpdateDataTable } from '../../../../hooks/dataTable'
import { FormDocument } from '../components/FormDocument'

import {
  FORM_VISAS_STATUS,
  FORM_VISAS_STATUS_OPTIONS,
  INPUT_MASK,
  SAVE_FORM_DATA
} from '../../../../common/constants'
import { Loading } from '../../../../components/Loading'
import { FormChangeStatusVisa } from '../components/FormChangeStatusVisa'
import { convertToBase64 } from '../../../../utils/convertToBase64'
import { useAuth } from '../../../../hooks/auth'
import moment from 'moment'
import { TITLES_ENUM } from '../../../Dashboard'
import { buildCallbackUrl } from '../../../../utils/url'

const ClientList: React.FC = () => {
  const history = useHistory()
  const { user } = useAuth()
  const { updateDataTable } = useUpdateDataTable()
  const { disableLoading, activeLoading } = useLoading()
  const [phasesProcess, setPhasesProcess] = useState<SelectOptions[]>([])
  const [phasesProcessData, setPhasesProcessData] = useState<
    PhaseProcessApiResponse[]
  >([])
  const [formTypes, setFormTypes] = useState<SelectOptions[]>([])
  const [formTypeSelected, setFormTypeSelected] = useState<string | null>()
  const [visasStatus, setVisasStatus] = useQueryParam(
    'visas_status',
    StringParam
  )
  const [formId] = useQueryParam('formId', StringParam)
  const [title] = useQueryParam('title', StringParam)
  const [status, setStatus] = useQueryParam('status', StringParam)
  const [titleId] = useQueryParam('title_id', StringParam)
  const [date, setDate] = useQueryParam('date', StringParam)
  const [endDateQuery, setEndDateQuery] = useQueryParam('endDate', StringParam)
  const [clearFilter, setClearFilter] = useState<any>()
  const [currentItemUpdate, setCurrentItemUpdate] = useState<any>({} as any)
  const [currentItemUpdateVisasStatus, setCurrentItemUpdateVisasStatus] =
    useState<any>({} as any)
  const [modalEdit, setModalEdit] = useState(false)
  const [searchParameters, setSearchParameters] = useState<any[]>([])
  const [openModalFolder, setOpenModalFolder] = useState(false)
  const [openModalStatusVisa, setOpenModalStatusVisa] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [currentForm, setCurrentForm] = useState<any>()

  const handleSetFilter = useCallback(
    data => {
      setIsLoading(true)
      setSearchParameters([data])
      setStatus('')
      setDate('')
      setEndDateQuery('')
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    },
    [setDate, setEndDateQuery, setStatus]
  )
  const handleClearFilter = useCallback(() => {
    setIsLoading(true)
    setClearFilter(true)
    setSearchParameters([])
    setTimeout(() => {
      setIsLoading(false)
      setClearFilter(false)
    }, 1000)
  }, [])

  useEffect(() => {
    setIsLoading(true)
    setSearchParameters([
      {
        id: formId,
        phase_process_id: status,
        start_date: date,
        end_date: endDateQuery,
        visas_status: visasStatus
      }
    ])
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClickOnClose = () => {
    setModalEdit(false)
  }

  const handleConsultancyClickOnClose = () => {
    setOpenModalStatusVisa(false)
  }
  const handleClickOnCloseDocuments = () => {
    setOpenModalFolder(false)
    updateDataTable()
  }

  const loadPhasesProcess = useCallback(async () => {
    activeLoading()
    try {
      const responsePhasesProcess = await api.get<PhaseProcessApiResponse[]>(
        '/commercial/phasesProcess'
      )
      setPhasesProcessData(responsePhasesProcess.data)
      const filterOptionsPhaseProcess = responsePhasesProcess.data.filter(
        phaseProcess => !phaseProcess.form_type_id
      )
      setPhasesProcess(
        filterOptionsPhaseProcess.map(phaseProcess => ({
          name: phaseProcess.name,
          value: phaseProcess.id
        }))
      )
      disableLoading()
    } catch (error) {
      disableLoading()
    }
  }, [activeLoading, disableLoading])

  const loadTypesVisas = useCallback(async () => {
    activeLoading()
    try {
      const responseFormTypes = await api.get<FormTypeApiResponse[]>(
        '/commercial/formTypes'
      )
      setFormTypes(
        responseFormTypes.data.map(formType => ({
          name: formType.name,
          value: formType.id
        }))
      )
      disableLoading()
    } catch (error) {
      disableLoading()
    }
  }, [activeLoading, disableLoading])

  useEffect(() => {
    loadPhasesProcess()
  }, [loadPhasesProcess])
  useEffect(() => {
    loadTypesVisas()
  }, [loadTypesVisas])
  if (clearFilter) {
    return <Loading isActive />
  }

  return (
    <>
      <Loading isActive={isLoading} />
      <Container
        pageTitle={title || 'Listagem'}
        breadcrumb={breadcrumbList}
        tools={[]}
      >
        <Form onSubmit={handleSetFilter} defaultValues={clearFilter}>
          <div className="form">
            <div className="row mb-5">
              <Input
                name="applicant_name"
                label="Aplicante"
                className="col-md-4"
              />
              <Select
                className="col-md-3"
                name="visas_status"
                label="Status do visto"
                options={FORM_VISAS_STATUS_OPTIONS}
                blank
                defaultValue=""
              />
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <div className="col-md-2 d-flex justify-content-end">
              <button
                type="reset"
                className="btn btn-light-primary"
                onClick={handleClearFilter}
              >
                Limpar
              </button>
            </div>
            <div className="col-md-1 d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                Filtrar
              </button>
            </div>
          </div>
        </Form>
        {!isLoading && (
          <DataTable
            source={nameSource}
            entity={nameEntity}
            format={{ orderBy: 'id', orderBySort: 'DESC' }}
            searchField="name"
            headers={headers}
            notHasChildren
            searchParameters={searchParameters}
            hasSearch={false}
            customHeaders={[
              // {
              //   name: 'Criado em',
              //   field: 'created_at',
              //   sortable: false,
              //   element: item => {
              //     return (
              //       <p>
              //         {moment(item?.created_at).format(
              //           INPUT_MASK.DATE_LOCALE_WITH_TIME
              //         )}
              //       </p>
              //     )
              //   }
              // },
              {
                name: 'Status',
                field: 'formPhaseProcess',
                sortable: true,
                element: item => {
                  let lastPhaseProcess: Partial<FormPhaseProcessApiResponse> = {
                    id: 0
                  }
                  item?.formPhaseProcess.forEach((formPhaseProcess: any) => {
                    if (lastPhaseProcess?.id < formPhaseProcess.id) {
                      lastPhaseProcess = formPhaseProcess
                    }
                  })
                  return <p>{lastPhaseProcess?.phaseProcess?.name || ''}</p>
                }
              },
              {
                name: 'Contratante',
                field: 'contract.client.name',
                sortable: true,
                element: item => {
                  return (
                    <p style={{ width: '300px' }}>
                      {item.contract?.client?.name}
                    </p>
                  )
                }
              },
              {
                name: 'Nome',
                field: 'applicant_name',
                sortable: true,
                element: item => {
                  return <p style={{ width: '300px' }}>{item.applicant_name}</p>
                }
              },
              {
                name: 'Sts. do visto',
                field: 'visas_status',
                sortable: true,
                element: item => {
                  return <p>{FORM_VISAS_STATUS[item.visas_status]}</p>
                }
              }
            ]}
            actions={[
              {
                name: 'Status de visto',
                title: 'Status de visto',
                spanIcon: 'fa-solid fa-check fa-lg',
                onClick: (item: any) => {
                  setCurrentItemUpdateVisasStatus(item)
                  setOpenModalStatusVisa(true)
                }
              },
              {
                name: 'Contrato',
                title: 'Contrato',
                spanIcon: 'fa-solid fa-rectangle-list fa-lg',
                onClick: (item: any) => {
                  let url = `/commercial/contracts/view/${item.contract.id}?`
                  if (TITLES_ENUM[titleId]) {
                    url += `callback_url=${convertToBase64(
                      TITLES_ENUM[titleId]
                    )}`
                  }
                  history.push(url)
                }
              },
              {
                name: 'Visualizar formulário',
                title: 'Visualizar formulário',
                spanIcon: 'fa fa-edit fa-lg',
                onClick: (item: any) => {
                  localStorage.removeItem(SAVE_FORM_DATA)
                  const convertedObject = convertToBase64(
                    JSON.stringify({
                      form_id: item.id,
                      user_type: 'admin',
                      url_callback: buildCallbackUrl({
                        baseUrl: process.env.REACT_APP_URL,
                        path: '/commercial/contracts/forms',
                        status,
                        title: title
                      }),
                      token: user.token
                    })
                  )
                  const a = document.createElement('a')
                  a.href = `${process.env.REACT_APP_FORMS_SERVICE}${item.formType.link}?pass=${convertedObject}`
                  a.click()
                  a.remove()
                }
              },
              {
                name: 'Status',
                title: 'Mudar Status',
                spanIcon: 'fa-regular fa-clipboard fa-lg',
                onClick: (item: any) => {
                  setModalEdit(true)
                  setCurrentItemUpdate(item)
                }
              },
              {
                name: 'Arquivos',
                title: 'Arquivos',
                onClick: (item: any) => {
                  setCurrentForm(item)
                  setOpenModalFolder(true)
                },
                element: () => {
                  return (
                    <div>
                      <span
                        className="fa fa-folder fa-lg"
                        style={{
                          color: '#009EF7'
                        }}
                      ></span>
                    </div>
                  )
                }
              }
            ]}
          />
        )}
      </Container>
      {openModalStatusVisa && (
        <Modal
          onClickButtonCancel={handleConsultancyClickOnClose}
          isOpenModal={openModalStatusVisa}
          pageTitle={`Status do visto - ${currentItemUpdateVisasStatus?.applicant_name}`}
          Children={
            <FormChangeStatusVisa
              typeForm="update"
              initialValues={{
                ...currentItemUpdateVisasStatus,
                idUpdate: currentItemUpdateVisasStatus?.id
              }}
              isOpenInModal={{
                handleOnClose: handleConsultancyClickOnClose,
                idParent: currentItemUpdateVisasStatus?.id
              }}
            />
          }
        />
      )}
      {modalEdit && (
        <Modal
          onClickButtonCancel={handleClickOnClose}
          isOpenModal={modalEdit}
          pageTitle={`Editar - ${currentItemUpdate?.applicant_name}`}
          Children={
            <FormChangeStatus
              typeForm="update"
              initialValues={{
                ...currentItemUpdate,
                idUpdate: currentItemUpdate?.id
              }}
              isOpenInModal={{
                handleOnClose: handleClickOnClose,
                idParent: currentItemUpdate?.id
              }}
            />
          }
        />
      )}

      {openModalFolder && (
        <Modal
          onClickButtonCancel={handleClickOnCloseDocuments}
          isOpenModal={openModalFolder}
          pageTitle={`Documentos - ${currentForm?.applicant_name}`}
          style={{
            padding: 0,
            stylesModal: {
              width: '1150px',
              overflow: 'auto'
            }
          }}
          Children={
            <FormDocument
              initialValues={{
                ...currentForm
              }}
              isOpenInModal={{
                handleOnClose: handleClickOnCloseDocuments
              }}
            />
          }
        />
      )}
    </>
  )
}

export default ClientList
