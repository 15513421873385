interface BuildUrlParams {
  baseUrl: string
  path: string
  title?: string
  status?: string
}

/**
 * Constrói uma URL segura com parâmetros codificados
 * @param baseUrl - URL base (ex: https://exemplo.com)
 * @param path - Caminho da rota (ex: /commercial/contracts/forms)
 * @param title - Título que será codificado como parâmetro (Opcional)
 * @param status - Status que será codificado como parâmetro (Opcional)
 * @returns URL completa e segura
 */
export const buildCallbackUrl = ({
  baseUrl = 'http://localhost:3001',
  path,
  status,
  title
}: BuildUrlParams): string => {
  const sanitizedBaseUrl = baseUrl.replace(/\/$/, '') // Remove barra final se existir
  const sanitizedPath = path.startsWith('/') ? path : `/${path}` // Garante que path começa com barra
  const encodedTitle = encodeURIComponent(title)

  let url = `${sanitizedBaseUrl}${sanitizedPath}`

  if (encodedTitle) {
    url += `?title=${encodedTitle}`
  }

  if (status) {
    url += `&status=${status}`
  }

  return url
}
