import React, { useCallback, useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select } from '../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { FormContainer } from './styles'
import { Alert } from '../../../../../components/Alert'
import moment from 'moment'
import { INPUT_MASK } from '../../../../../common/constants'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}
type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: any & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}
const toastSuccessData: Record<string, () => ToastMessage> = {
  create: () => ({
    type: 'success',
    title: 'Registro criado',
    description: 'Registro criado com sucesso'
  }),
  update: () => ({
    type: 'success',
    title: 'Registro atualizado',
    description: 'Registro alterado com sucesso'
  })
}
const toastErrorData: Record<string, () => ToastMessage> = {
  create: () => ({
    type: 'error',
    title: 'Erro ao adicionar o registro',
    description:
      'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
  }),
  update: () => ({
    type: 'error',
    title: 'Erro ao atualizar',
    description:
      'Ocorreu um erro ao alterar os dados, por favor, tente novamente.'
  })
}

export const FormLeads = ({
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const { activeLoading, disableLoading } = useLoading()
  const [alert, setIsActiveAlert] =
    useState<{ isActive: boolean; document: File }>()

  const onSubmitForm = useCallback(
    async (data: any) => {
      const id = initialValues?.idUpdate
      const apiRequest =
        typeForm === 'create'
          ? api.post(apiCreate(), data)
          : api.put(apiUpdate(id.toString()), data)

      try {
        activeLoading()
        await apiRequest
        disableLoading()
        addToast(toastSuccessData[typeForm]?.())
        if (typeForm === 'update') {
          updateDataTable()
        }
        if (initialValues?.statusQuery) {
          let url = `/commercial/leads?status=${initialValues?.statusQuery}`
          if (initialValues?.titleQuery) {
            url += `&title=${initialValues?.titleQuery}`
          }
          history.push(url)
          return
        }
        history.push(nameActions.read.to)
      } catch (error) {
        history.push(nameActions.read.to)
        addToast(toastErrorData[typeForm]?.())
      }
    },
    [
      activeLoading,
      addToast,
      disableLoading,
      history,
      initialValues?.idUpdate,
      initialValues?.statusQuery,
      initialValues?.titleQuery,
      typeForm,
      updateDataTable
    ]
  )

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert(oldAlert => ({
      ...oldAlert,
      isActive: false
    }))
  }

  return (
    <>
      <Form
        onSubmit={onSubmitForm}
        defaultValues={{
          name: initialValues?.name,
          email: initialValues?.email,
          phone: initialValues?.phone,
          status: initialValues?.status
        }}
      >
        <>
          <div className="card">
            <FormContainer>
              <div className="row mb-5">
                <Input
                  name="name"
                  label="Nome"
                  rules={{ required: true }}
                  className="col-md-4"
                />
                <Input
                  name="phone"
                  label="Celular"
                  rules={{ required: true }}
                  className="col-md-2"
                  mask={{
                    mask: INPUT_MASK.CELL_PHONE
                  }}
                  controlled
                />
                <Input
                  name="email"
                  label="Email"
                  rules={{ required: true }}
                  className="col-md-4"
                />
                <Select
                  name="status"
                  label="Status"
                  rules={{ required: false }}
                  className="col-md-2"
                  options={[
                    { name: 'Novo', value: 'new' },
                    { name: 'Em andamento', value: 'in_progress' },
                    { name: 'Fidelizado', value: 'loyal' },
                    { name: 'Abandono', value: 'abandonment' }
                  ]}
                  defaultValue=""
                  blank
                />
              </div>
            </FormContainer>
            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <button type="submit" className="btn btn-primary">
                Salvar
              </button>
            </div>
          </div>
        </>
      </Form>
      {alert && alert.isActive && (
        <Alert
          message={`Tem certeza que deseja excluir esse documento ${alert.document.name} ? ele não poderá ser recuperado`}
          onClickCancellButton={handlerClickButtonCancellAlert}
          onClickConfirmButton={() => {
            // handleRemoveFile(alert.document)
            setIsActiveAlert(oldAlert => ({
              ...oldAlert,
              isActive: false
            }))
          }}
          isActive={alert.isActive}
        />
      )}
    </>
  )
}
