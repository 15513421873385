import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Container from '../../../../components/Container'
import { useLoading } from '../../../../hooks/loading'
import api from '../../../../services/api'
import { FormLeads } from '../components/Form'
import { apiList } from '../domain/api'
import { nameActions } from '../domain/info'
import { breadcrumbUpdate } from '../domain/breadcrumb'
import { toolsUpdate } from '../domain/tools'
import { useToast } from '../../../../hooks/toast'
import { StringParam, useQueryParam } from 'use-query-params'

const LeadUpdate = (): JSX.Element => {
  const { addToast } = useToast()
  const location = useLocation()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const { activeLoading, disableLoading } = useLoading()
  const [leadsItem, setLeadsItem] = useState<any | undefined>()
  const [statusQuery] = useQueryParam('status', StringParam)
  const [titleQuery] = useQueryParam('title', StringParam)

  useEffect(() => {
    async function loadData() {
      activeLoading()
      try {
        const response = await api.get(apiList(id))
        const { data } = response
        setLeadsItem(data)
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o lead',
          description:
            'Houve um error ao carregar o lead, tente novamente mais tarde!'
        })
        if (path.includes('update')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadData()
  }, [activeLoading, addToast, disableLoading, history, id, location.pathname])

  return (
    <Container
      pageTitle={nameActions.update.name}
      breadcrumb={breadcrumbUpdate}
      tools={toolsUpdate}
    >
      <FormLeads
        typeForm="update"
        initialValues={{
          idUpdate: Number(leadsItem?.id),
          ...leadsItem,
          statusQuery,
          titleQuery
        }}
      />
    </Container>
  )
}

export default LeadUpdate
