import React, { useEffect, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Container from '../../../../components/Container'
import api from '../../../../services/api'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import { nameActions } from '../domain/info'
import { apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import { toolsViewUpdate, toolsViewList } from '../domain/tools'
import { LeadsApiResponse } from '../../../../@types/leads'
import { LEAD_STATUS_ENUM } from '../../../../common/constants/lead-status'

const LeadsView = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const { addToast } = useToast()

  const { disableLoading, activeLoading } = useLoading()
  const [leads, setLeads] = useState<LeadsApiResponse | null>(null)

  useEffect(() => {
    activeLoading()
    async function loadLeads(): Promise<void> {
      activeLoading()
      try {
        const responseLead = await api.get<LeadsApiResponse>(apiList(id))
        setLeads({ ...responseLead.data })

        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o leade',
          description:
            'Houve um error ao carregar o leade, tente novamente mais tarde!'
        })
        if (path.includes('view')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadLeads()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLoading, addToast, disableLoading, history, location.pathname])

  return (
    <div className="d-flex flex-column container-fluid">
      <>
        <Container
          pageTitle={nameActions.read.name}
          breadcrumb={breadcrumbView}
          tools={[toolsViewUpdate(String(id)), toolsViewList()]}
        >
          <div className="form-body">
            <div className="row mb-5">
              <div className="col-md-1">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Cód.
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">{leads?.id}</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Nome
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">{leads?.name}</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Email
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">{leads?.email}</p>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Celular
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">{leads?.phone}</p>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Status
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {leads?.status
                      ? LEAD_STATUS_ENUM[leads?.status]
                      : 'Sem status'}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </>
    </div>
  )
}

export default LeadsView
