export const LEAD_STATUS_ENUM: Record<string, string> = {
  new: 'Novo',
  in_progress: 'Em Progresso',
  loyal: 'Fidelizado',
  abandonment: 'Abandono'
}

export const LEAD_STATUS_ENUM_OPTIONS = Object.entries(LEAD_STATUS_ENUM).map(
  ([key, value]) => ({
    name: value,
    value: key
  })
)
